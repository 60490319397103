import { Controller } from 'stimulus'

export default class extends Controller {
  connect() {
    this.startPolling()
    this.setupCompleted = false
  }

  disconnect() {
    clearInterval(this.interval)
  }

  startPolling() {
    this.interval = setInterval(() => { this.poll() }, 1500)
  }

  poll() {
    const url = this.data.get('url')
    const redirectionUrl = this.data.get('redirection-url');
    
    fetch(url)
      .then(response => response.text())
      .then(text => JSON.parse(text))
      .then(json => {
        if (!this.setupCompleted && json.setup_completed && json.email_marketing_account_dns_verification_ready) {
          this.setupCompleted = true
          clearInterval(this.interval)

          window.location.href = redirectionUrl;
        }
      })
  }
}
