import { Controller } from 'stimulus'
import { eventTracker } from '../../utilities/customer'

export default class extends Controller {
  initialize() {
    this.createEvent('viewed')
  }

  createWebinarCommunicationView(e) {
    $.ajax({
      url: '/api/v1/webinar_communication_views',
      type: 'POST',
      data: {
        webinar_communication_id: this.data.get('webinar-communication-id'),
        cta: e.detail.targetId || e.target.id,
        object: this.data.get('object')
      }
    });
  }

  onClick(e) {
    e.preventDefault()
    this.createEvent('clicked', { type: this.setEventType(e) })
    
    this.createWebinarCommunicationView(e)
  }

  setEventType(e) {
    return (e.currentTarget.id || e.detail.targetId) === 'cta-button' ? 'cta' : 'exit'
  }

  openCtaWindow(e) {
    window.open(e.currentTarget.dataset.url, '_blank')
  }

  createEvent(action, extraProperties) {
    const object = this.data.get('object')
    const flow = this.data.get('request-controller')
    const properties = Object.assign({}, { flow, object, action }, extraProperties)

    eventTracker.track(`${object}.${action}`, properties)
  }
}
