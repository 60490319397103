import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'customText',
  ]

  connect() {
    this.load();
  }

  load() {
    this.customTextTarget.addEventListener('input', this.adjustHeight.bind(this));

    this.adjustHeight();
  }

  adjustHeight() {
    this.customTextTarget.style.height = 'auto';

    if ($(this.customTextTarget).is(':visible')) {
      this.customTextTarget.style.height = `${this.customTextTarget.scrollHeight + 10}px`;
    }
  }
}
