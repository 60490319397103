import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'emailMarketingProviderInput',
    'otherEmailMarketingProviderContainer',
  ]

  connect() {
    this.toggleOtherEmailMarketingProvider()
  }

  toggleOtherEmailMarketingProvider() {
    if ($(this.emailMarketingProviderInputTarget).val() === 'other') {
      $(this.otherEmailMarketingProviderContainerTarget).show()
    } else {
      $(this.otherEmailMarketingProviderContainerTarget).hide()
    }
  }
}

